import React from 'react';
import './Header.css';
import Lottie from 'lottie-react';
import headeranimation from '../assets/headeranimation.json';
import { motion } from 'framer-motion';

const Header = () => {
  return (
    <header className="header-wrapper">
      <div className="header-content">
        <motion.div 
          className="text-container"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="company-tag">Welcome to</div>
          <h1>Shawave Technologies</h1>
          <div className="subtitle">Your Solutions Partner for a Better Tomorrow</div>
          <div className="divider"></div>
          <p className="description">
            At Shawave, we are committed to providing innovative solutions to tackle everyday challenges. 
            Our mission is to create a suite of services that simplify life, enhance efficiency, and drive progress. 
            We start with our premier service, Driver Spot, and have an exciting roadmap of future offerings 
            that will further transform how you live and work.
          </p>
          <motion.div 
            className="cta-buttons"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <button className="primary-btn">Get Started</button>
            <button className="secondary-btn">Learn More</button>
          </motion.div>
        </motion.div>

        <motion.div 
          className="image-container"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <Lottie 
            animationData={headeranimation} 
            loop={true} 
            className="animation-wrapper"
          />
        </motion.div>
      </div>
    </header>
  );
};

export default Header;
