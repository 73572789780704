import React from 'react';
import { motion } from 'framer-motion';
import driverspotlogo from './assets/DriverSpot  Logo_D3.png';
import './Product.css';

export default function Product() {
  return (
    <div className="product-page">
      <section className="product-hero">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="hero-content"
        >
          <img 
            src={driverspotlogo} 
            alt="DriverSpot Logo" 
            className="hero-logo"
          />
          <p className="hero-subtitle">Revolutionizing Driver Management Solutions</p>
        </motion.div>
      </section>

      <section className="product-overview">
        <div className="section-container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="overview-content"
          >
            <div className="overview-text">
              <h2>Transform Your Transportation Operations</h2>
              <p>Experience seamless driver management with our comprehensive platform designed for modern businesses.</p>
              <div className="key-features">
                <div className="feature">
                  <span className="feature-icon">🎯</span>
                  <span>Smart Matching</span>
                </div>
                <div className="feature">
                  <span className="feature-icon">📱</span>
                  <span>Real-time Tracking</span>
                </div>
                <div className="feature">
                  <span className="feature-icon">⚡</span>
                  <span>Instant Booking</span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      <section className="product-features">
        <div className="section-container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="section-header"
          >
            <h2>Comprehensive Solutions</h2>
            <p>Tailored services for both individual consumers and businesses</p>
          </motion.div>

          {/* D2C Services Section */}
          <div className="services-section">
            <motion.h3 
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="service-type-header"
            >
              Consumer Services (D2C)
            </motion.h3>
            <div className="features-grid d2c-grid">
              {[
                {
                  title: "Afterparty Service",
                  description: "Safe and reliable transportation after your events with professional drivers",
                  icon: "🌙"
                },
                {
                  title: "In-city Service",
                  description: "Convenient transportation within city limits for all your daily needs",
                  icon: "🏙️"
                },
                {
                  title: "Outstation Service",
                  description: "Reliable long-distance travel solutions with experienced drivers",
                  icon: "🚗"
                },
                {
                  title: "Bulk Chauffer Services",
                  description: "Multiple drivers for group transportation needs and events",
                  icon: "👥"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  className="feature-card d2c-card"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ y: -5 }}
                >
                  <span className="feature-icon">{feature.icon}</span>
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>

          {/* B2B Services Section */}
          <div className="services-section">
            <motion.h3 
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="service-type-header"
            >
              Business Solutions (B2B)
            </motion.h3>
            <div className="features-grid b2b-grid">
              {[
                {
                  title: "Corporate Transportation",
                  description: "Regular employee pick-up and drop-off services with flexible scheduling, dedicated drivers for regular routes, monthly billing options, employee tracking portal, and customized reporting",
                  icon: "🏢"
                },
                {
                  title: "Event Management",
                  description: "Comprehensive transportation solutions for corporate events and conferences with multiple vehicle coordination, on-site coordinator, guest list management, and real-time fleet tracking",
                  icon: "🎉"
                },
                {
                  title: "Valet Management",
                  description: "Professional valet services for hotels, restaurants, and events with trained valet staff, digital parking management, insurance coverage, and peak hour optimization",
                  icon: "🎯"
                },
                {
                  title: "Function Hall Services",
                  description: "Dedicated driver solutions for function halls and event venues with pre-vetted professional drivers, flexible booking options, 24/7 availability, and bulk booking discounts",
                  icon: "🏛️"
                },
                {
                  title: "Service Center Solutions",
                  description: "Vehicle pick-up and drop services for automotive service centers with scheduled services, real-time status updates, digital documentation, and customer communication management",
                  icon: "🔧"
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  className="feature-card b2b-card"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ y: -5 }}
                >
                  <span className="feature-icon">{feature.icon}</span>
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="product-cta">
        <div className="section-container">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="cta-content"
          >
            <h2>Ready to Experience Stress-Free Travelling?</h2>
            <p>Transform your transportation experience with DriverSpot</p>
            <div className="cta-buttons">
              <button className="primary-button">Book Now</button>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}
