import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'
import { FaPhone, FaEnvelope, FaInstagram, FaWhatsapp, FaLinkedin, FaMapMarkerAlt } from 'react-icons/fa'
import logo from '../assets/logo.png'
export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section company-info">
          <img 
            src={logo}
            alt="DriverSpot Logo" 
            className="footer-logo" 
          />
          <p className="company-description">
            Revolutionizing transportation management with innovative solutions for modern businesses.
          </p>
          <div className="social-links">
            <a href="https://www.instagram.com/shawave_technologies_pvt_ltd" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://wa.me/919959019781" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
            <a href="https://www.linkedin.com/company/shawave-technologies" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>

        <div className="footer-section quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/product">Product</Link></li>
            <li><Link to="/contactus">Contact</Link></li>
          </ul>
        </div>

        <div className="footer-section contact-info">
          <h3>Contact Info</h3>
          <div className="contact-details">
            <p>
              <FaMapMarkerAlt />
              <span>Hyderabad, Telangana, India</span>
            </p>
            <p>
              <FaPhone />
              <span>+91 9959019781</span>
            </p>
            <p>
              <FaEnvelope />
              <span>sunanthsamala@shawavetechnologies.com</span>
            </p>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p>&copy; 2024 DriverSpot. All rights reserved.</p>
          <div className="legal-links">
            <Link to="/Terms&conditions">Terms & Conditions</Link>
            <Link to="/PrivacyPolicy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
