import React from 'react';
import './About.css';
import sunanthImage from '../assets/sunanth.png';
import rushikanthImage from '../assets/rushi1.jpg';
import { FaCheckCircle, FaLightbulb, FaUsers, FaRocket } from 'react-icons/fa';
import icon from '../assets/icon.png';
import { motion } from 'framer-motion';

export default function AboutUs() {
  const stats = [
    { icon: <FaLightbulb />, number: "50+", label: "Projects Completed" },
    { icon: <FaUsers />, number: "100+", label: "Happy Clients" },
    { icon: <FaRocket />, number: "5+", label: "Years Experience" }
  ];

  const values = [
    { title: "Innovation", description: "Pushing boundaries with cutting-edge solutions" },
    { title: "Quality", description: "Delivering excellence in every project" },
    { title: "Reliability", description: "Building trust through consistent performance" },
    { title: "Customer Focus", description: "Your success is our priority" }
  ];

  return (
    <div className="about-page">
      {/* Hero Section */}
      <motion.section 
        className="about-hero"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="hero-content">
          <h1>About Shawave Technologies</h1>
          <p>Transforming Ideas into Digital Reality</p>
        </div>
      </motion.section>

      {/* Company Overview */}
      <section className="company-section">
        <div className="section-container">
          <motion.div 
            className="company-content"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <div className="company-image">
              <img src={icon} alt="Shawave Technologies" className="company-logo" />
            </div>
            <div className="company-text">
              <h2>Who We Are</h2>
              <p>
                At <span className="highlight">Shawave Technologies</span>, we are dedicated to developing innovative solutions that tackle everyday challenges. Our mission is to create products that enhance security, efficiency, and convenience for our clients. From cutting-edge technology to user-friendly applications, we strive to make a meaningful impact on people's lives.
              </p>
              <div className="features-list">
                <div className="feature-item">
                  <FaCheckCircle className="icon" />
                  <span>Innovative Technologies</span>
                </div>
                <div className="feature-item">
                  <FaCheckCircle className="icon" />
                  <span>Customer-Centric Approach</span>
                </div>
                <div className="feature-item">
                  <FaCheckCircle className="icon" />
                  <span>Reliable Support</span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Stats Section */}
      

      {/* Values Section */}
      <section className="values-section">
        <div className="section-container">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Our Core Values
          </motion.h2>
          <div className="values-grid">
            {values.map((value, index) => (
              <motion.div 
                key={index}
                className="value-card"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Founders Section */}
      <section className="founders-section">
        <div className="section-container">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Meet Our Founders
          </motion.h2>
          <div className="founders-grid">
            <motion.div 
              className="founder-card"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <div className="founder-image-container">
                <img src={sunanthImage} alt="Sunanth" />
              </div>
              <div className="founder-info">
                <h3>Sunanth Samala</h3>
                <p className="founder-title">Founder & CEO</p>
                <p className="founder-description">
                  A visionary leader with expertise in software development and business strategy. Sunanth combines technical excellence with entrepreneurial insight to drive innovation in the mobility sector.
                </p>
              </div>
            </motion.div>

            <motion.div 
              className="founder-card"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <div className="founder-image-container">
                <img src={rushikanthImage} alt="Rushikanth" />
              </div>
              <div className="founder-info">
                <h3>Rushikanth</h3>
                <p className="founder-title">Co-Founder & COO</p>
                <p className="founder-description">
                  With a strong background in technology and two years of experience at TCS, Rushikanth leads the technical vision and implementation of our innovative solutions.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
}


