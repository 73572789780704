import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Contactus.css';
import { FaPhone, FaEnvelope, FaInstagram, FaWhatsapp, FaLinkedin } from 'react-icons/fa';

export default function Contactus() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to a server)
    console.log('Form Data Submitted:', formData);
  };

  const contactInfo = [
    {
      icon: <FaPhone />,
      title: 'Mobile',
      value: '+91 9959019781',
      link: 'tel:+919959019781',
      color: '#4CAF50'
    },
    {
      icon: <FaEnvelope />,
      title: 'Email',
      value: 'sunanthsamala@shawavetechnologies.com',
      link: 'mailto:sunanthsamala@shawavetechnologies.com',
      color: '#D93025'
    },
    {
      icon: <FaWhatsapp />,
      title: 'WhatsApp',
      value: '+91 9959019781',
      link: 'https://wa.me/919959019781',
      color: '#25D366'
    },
    {
      icon: <FaInstagram />,
      title: 'Instagram',
      value: '@shawave_technologies_pvt_ltd',
      link: 'https://www.instagram.com/shawave_technologies_pvt_ltd',
      color: '#E4405F'
    },
    {
      icon: <FaLinkedin />,
      title: 'LinkedIn',
      value: 'Shawave Technologies',
      link: 'https://www.linkedin.com/company/shawave-technologies',
      color: '#0A66C2'
    }
  ];

  return (
    <div className="contact-page">
      <div className="contact-container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="contact-header"
        >
          <h1>Contact Us</h1>
          <p>Connect with us through any of these platforms</p>
        </motion.div>

        <motion.div 
          className="contact-grid"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          {contactInfo.map((info, index) => (
            <motion.a
              key={index}
              href={info.link}
              target={info.title !== 'Mobile' ? '_blank' : undefined}
              rel={info.title !== 'Mobile' ? 'noopener noreferrer' : undefined}
              className="contact-card"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ 
                scale: 1.02,
                boxShadow: '0 8px 30px rgba(0,0,0,0.12)'
              }}
              whileTap={{ scale: 0.98 }}
            >
              <div 
                className="icon-wrapper" 
                style={{ 
                  backgroundColor: `${info.color}15`,
                  border: `1px solid ${info.color}30`
                }}
              >
                <span className="icon" style={{ color: info.color }}>
                  {info.icon}
                </span>
              </div>
              <div className="contact-info">
                <h3>{info.title}</h3>
                <p>{info.value}</p>
              </div>
            </motion.a>
          ))}
        </motion.div>

        <motion.form 
          className="contact-form"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="How can we help you?"
              required
            ></textarea>
          </div>

          <motion.button
            type="submit"
            className="submit-button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Send Message
          </motion.button>
        </motion.form>
      </div>
    </div>
  );
}


