import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaMobile, FaGlobe, FaDatabase, FaCode, FaLayerGroup, FaServer,
  FaShieldAlt, FaRocket, FaTools, FaCogs, FaCheckCircle
} from 'react-icons/fa';
import Header from './Header';
import './Home.css';
import driverspotlogo from './assets/DriverSpot  Logo_D3.png'
import getapp from '../assets/getapp.png'

const Home = () => {
  const services = [
    {
      icon: <FaMobile />,
      title: "Mobile App Development",
      description: "Transform your ideas into powerful mobile applications",
      features: [
        "Native iOS and Android Development",
        "Cross-platform Solutions with React Native",
        "UI/UX Design and Prototyping",
        "App Store Optimization and Deployment"
      ],
      color: "#4CAF50",
      technologies: "React Native • Flutter • iOS • Android"
    },
    {
      icon: <FaGlobe />,
      title: "Web Development",
      description: "Create stunning web experiences that drive results",
      features: [
        "Responsive Web Applications",
        "Progressive Web Apps (PWA)",
        "E-commerce Solutions",
        "Custom Web Portals"
      ],
      color: "#2196F3",
      technologies: "React.js • Next.js • Angular • Vue.js"
    },
    {
      icon: <FaLayerGroup />,
      title: "Full Stack Development",
      description: "End-to-end solutions for complex business needs",
      features: [
        "Scalable Architecture Design",
        "API Development & Integration",
        "Cloud Infrastructure Setup",
        "Performance Optimization"
      ],
      color: "#9C27B0",
      technologies: "MERN Stack • Python • Java • Cloud Services"
    },
    {
      icon: <FaCode />,
      title: "Frontend Development",
      description: "Beautiful and intuitive user interfaces",
      features: [
        "Responsive Design Implementation",
        "Modern Framework Integration",
        "Performance Optimization",
        "Cross-browser Compatibility"
      ],
      color: "#FF9800",
      technologies: "HTML5 • CSS3 • JavaScript • Modern Frameworks"
    },
    {
      icon: <FaServer />,
      title: "Backend Development",
      description: "Robust and scalable server-side solutions",
      features: [
        "API Development",
        "Database Design",
        "Authentication & Security",
        "Server Management"
      ],
      color: "#F44336",
      technologies: "Node.js • Python • Java • PHP"
    },
    {
      icon: <FaDatabase />,
      title: "Database Management",
      description: "Efficient data storage and management solutions",
      features: [
        "Database Architecture Design",
        "Performance Optimization",
        "Data Migration & Backup",
        "Security Implementation"
      ],
      color: "#00BCD4",
      technologies: "MongoDB • MySQL • PostgreSQL • Redis"
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="home"
    >
      <Header />
      
      <section className="services-section">
        <div className="services-container">
          <motion.div 
            className="services-header"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <span className="section-subtitle">What We Offer</span>
            <h2>Our Services</h2>
            <p>Delivering cutting-edge solutions with proven expertise and reliable support</p>
            
            <div className="trust-indicators">
              <div className="trust-item">
                <FaShieldAlt />
                <span>Secure & Reliable</span>
              </div>
              <div className="trust-item">
                <FaRocket />
                <span>Fast Delivery</span>
              </div>
              <div className="trust-item">
                <FaTools />
                <span>24/7 Support</span>
              </div>
            </div>
          </motion.div>

          <div className="services-grid">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className="service-card"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 + 0.3 }}
                whileHover={{ 
                  scale: 1.02,
                  boxShadow: '0 10px 30px rgba(0,0,0,0.1)'
                }}
              >
                <div className="service-icon" style={{ backgroundColor: `${service.color}15`, color: service.color }}>
                  {service.icon}
                </div>
                <h3>{service.title}</h3>
                <p className="service-description">{service.description}</p>
                
                <ul className="service-features">
                  {service.features.map((feature, idx) => (
                    <li key={idx}>
                      <FaCheckCircle className="feature-icon" />
                      {feature}
                    </li>
                  ))}
                </ul>
                
                <div className="service-technologies">
                  <span className="tech-label">Technologies:</span>
                  <span className="tech-stack">{service.technologies}</span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Home;
