import React from 'react';
import { motion } from 'framer-motion';
import './Services.css';

const Services = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="services-container"
    >
      <h1>Our Services</h1>
      <div className="services-grid">
        <ServiceCard
          title="Mobile App Development"
          description="Native and cross-platform mobile applications for iOS and Android"
          icon="📱"
        />
        <ServiceCard
          title="Web Development"
          description="Responsive and modern web applications using latest technologies"
          icon="💻"
        />
        <ServiceCard
          title="UI/UX Design"
          description="User-centered design solutions that enhance user experience"
          icon="🎨"
        />
      </div>
    </motion.div>
  );
};

const ServiceCard = ({ title, description, icon }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="service-card"
    >
      <div className="service-icon">{icon}</div>
      <h3>{title}</h3>
      <p>{description}</p>
    </motion.div>
  );
};

export default Services; 