import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './Screens/Footer';
import Home from './Screens/Home';
import About from './Screens/About';
import Navbar from './Screens/Navbar';
import Privacypolicy from './Screens/Privacypolicy';
import Terms from './Screens/Terms';
import Login from './Screens/Drivermanagement/Login';
import Contactus from './Screens/Contactus';
import Chatsupport from './Screens/CustomerSupport/Chatsupport';
import UserChat from './Screens/CustomerSupport/UserChat';
import Services from './Screens/Services';
import Product from './Screens/Product';
import { AnimatePresence } from 'framer-motion';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/PrivacyPolicy" element={<Privacypolicy />} />
            <Route path="/Terms&Conditions" element={<Terms />} />
            <Route path="/Driver" element={<Login />} />
            <Route path="/Support" element={<Chatsupport />} />
            <Route path="/Supportuser" element={<UserChat />} />
            <Route path="/services" element={<Services />} />
            <Route path="/product" element={<Product />} />
          </Routes>
        </AnimatePresence>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
